import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminPageInitialState, AdminPageTypes } from './admin.state.ts';
import { getReceptionCenters } from 'store/feature/admin_page/admin.thunk.ts';
import { QueryState } from '@/common/enum';
import { ISettingsEntity } from 'interfaces/settings.interface.ts';

const initialState: AdminPageTypes = AdminPageInitialState;

export const adminPageSlice = createSlice({
  name: 'adminPage',
  initialState,
  reducers: {
    UPDATE_STATION_SETTINGS: (state, action: PayloadAction<ISettingsEntity>) => {
      state.stationSettings = action.payload;
    },
    UPDATE_STATE_STATUS: (state, action: PayloadAction<QueryState>) => {
      state.queryState = action.payload;
    },
    UPDATE_NEW_ENTRY: (state, action: PayloadAction<boolean>) => {
      state.newEntry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReceptionCenters.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(getReceptionCenters.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.receptionCenters =
          (action.payload &&
            action.payload?.dataset &&
            action.payload.dataset.length &&
            action.payload.dataset) ||
          [];
      })
      .addCase(getReceptionCenters.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default adminPageSlice.reducer;
