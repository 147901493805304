import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IReceptionCenter } from 'interfaces/reception_centers.interface.ts';
import { ISettingsEntity } from 'interfaces/settings.interface.ts';

export type AdminPageTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  receptionCenters: IReceptionCenter[];
  stationSettings: Nullable<ISettingsEntity>;
  newEntry: boolean;
};

export const AdminPageInitialState: AdminPageTypes = {
  queryState: QueryState.UNDEFINED,
  receptionCenters: [],
  stationSettings: null,
  error: null,
  newEntry: false,
};
